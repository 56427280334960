export default {
  Kurdish: {
    staffs: "ستافەکان",
    search: "گەڕان",
    ID: "کۆد",
    staff_name: "ناوی ستاف",
    en_name: "سەرپەرشتیار",
    employees: "کارمەندان",
    emp_name: "ناو",
    salary_type: "جۆری مووچە",
    salary: "مووچە",
    emp_btn: "بینینی کارمەندان",
    monthly: "مانگانە",
    daily: "ڕۆژانە",
    dsl: "لیستی کارکردنی ڕۆژانە",
    month: "مانگ",
    year: "ساڵ",
    seen: "بینین",
    location: "شوێن",
    date: "بەروار",
    list_id: "ژ.لیست",
    list_deatail: "ناوەڕۆکی لیست",
    overtime: "کاتی زیادە",
    working_time: "کاتی کارکردن",
    fine: "بڕی سەرپێچی",
    fine_reason: "هۆکاری سەرپێجی",
    present: "ئامادەبوو",
    absent: "نەهاتوو",
    off: "ئۆف(پشوو)",
    main_staff: "ستافی سەرەکی",
    note: "تێبینی",
    status: "دۆخ",
    food_money: "پارەی خواردن",
    transport_money: "کرێی هاتووچۆ",
    active: "چالاک",
    deactive: "ناچالاک"
  },
  English: {
    staffs: "Staffs",
    search: "Search",
    ID: "ID",
    staff_name: "Staff Name",
    en_name: "Supervisor",
    employees: "Employees",
    emp_name: "Name",
    salary_type: "Salary Type",
    salary: "Salary",
    emp_btn: "See Employees",
    monthly: "Monthly",
    daily: "Daily",
    dsl: "Daily Staff List",
    month: "Month",
    year: "Year",
    seen: "Seen",
    location: "Location",
    date: "Date",
    list_id: "List ID",
    list_deatail: "List Deatils",
    overtime: "Overtime",
    working_time: "Working Time",
    fine: "Fine",
    fine_reason: "Fine Reason",
    present: "Present",
    absent: "Absent",
    off: "Off",
    main_staff: "Main Staff",
    note: "Note",
    status: "Status",
    food_money: "Food money",
    transport_money: "Transport money",
    active: "Active",
    deactive: "Deactive"
  },
  Arabic: {
    staffs: "العاملين",
    search: "بحث",
    ID: "رقـم",
    staff_name: "اسم العاملین",
    en_name: "مشرف",
    employees: "الموظفين",
    emp_name: "اسـم",
    salary_type: "نوع الراتب",
    salary: "راتب",
    emp_btn: "رؤية الموظفين",
    monthly: "شهريا",
    daily: "اليومي",
    dsl: "قائمة العمل اليومية",
    month: "شهـر",
    year: "عـام",
    seen: "المحتوى",
    location: "موقع",
    date: "التاریخ",
    list_id: "رقم القائمة",
    list_deatail: "محتوى القائمة",
    overtime: "وقت إضافي",
    working_time: "وقت العمل",
    fine: "مقدار الغرامة",
    fine_reason: "سـبب الغرامة",
    present: "كان حاضرا",
    absent: "غیـاب",
    off: "يوم الاجازة",
    main_staff: "ستاف رئیسیة",
    note: "ملاحظة",
    status: "حالة",
    food_money: "أموال الطعام",
    transport_money: "تكلفة النقل",
    active: "نشيط",
    deactive: "معاق"
  }
};