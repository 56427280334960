<template>
    <div id="wrapper">
        <navbar id="nav" class="animate__animated animate__fadeInLeft"  style="display: none;"/>
        <div class="d-flex flex-column animate__animated animate__fadeInRight" id="content-wrapper" style="display: none !important;">
            <div id="content">
                <header-btn/>
                <div class="container-fluid text-dark">
                    <h3 class="text-dark multi-language nrt"> {{stafflanguage[language].dsl}} </h3>
                    <hr>
                    <div class="row nrt multi-language"  style="overflow-x: auto;">
                        <label class="col-lg-1 col-xl-1 col-sm-1 mt-1 no-print"> {{stafflanguage[language].month}} : </label>
                        <select class="form-control col-sm-1 no-print" v-model="month">
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                        </select>

                        <label class="col-sm-1 mt-1 no-print"> {{stafflanguage[language].year}} : </label>
                        <input type="number" class="form-control col-sm-1 no-print" v-model="year">

                        
                        <label class="col-lg-2 col-xl-2 col-sm-2 mt-1 no-print"> {{stafflanguage[language].staff_name}} : </label>
                        <select class="form-control col-xl-2 col-lg-2 col-sm-7 no-print" v-model="st_id">
                            <option value=""></option>
                            <option v-for="staff in staffs.filter(obj => {
                                    if(user.role == 'E') {
                                        return true
                                    } else {
                                        return obj.special_staff == showSpecialStaffs
                                    }
                                })" :key="staff.st_id" v-bind:value="staff.st_id"> {{ staff.staff_name }} </option>
                        </select>
                        <span class="col-1" v-if="user.role == 'A' || user.role == 'K'">
                            <v-checkbox class="mt-1 custom-checkbox-label" v-model="showSpecialStaffs" true-value="true" false-value="false" label="Specials"></v-checkbox>
                        </span>
                        <button class="btn btn-success btn-sm-block mx-3 no-print" v-on:click="search()"> {{stafflanguage[language].search}} <i class="fa fa-search"></i> </button>
                        
                        <label class="float-right my-3">  </label>
                        <table class="table table-bordered table-striped text-dark text-center mt-2">
                            <thead> 
                                <th style="width: 13%"> {{stafflanguage[language].list_id}} </th>
                                <th style="width: 15%"> {{stafflanguage[language].date}} </th>
                                <th style="width: 20%"> {{stafflanguage[language].location}} </th>
                                <th style="width: 20%;"> {{stafflanguage[language].note}} </th>
                                <th style="width: 20%;"> Date Time </th>
                                <th> {{stafflanguage[language].seen}} </th>
                            </thead>
                            <tbody>
                                <tr v-for="list in lists" :key="list.dsl_id" v-bind:class="{ 'bg-warning': list.location.toLowerCase() == 'rest' }"> 
                                    <td> {{ list.dsl_id }} </td>
                                    <td> {{ list.work_date | date_filter }} </td>
                                    <td> {{ list.location }} </td>
                                    <td> {{ list.note }} </td>
                                    <td>
                                        {{ list.datetime_list ? new Date(list.datetime_list).toISOString().split('T')[0] : '' }}
                                        {{ list.datetime_list ? new Date(list.datetime_list).toLocaleTimeString([], {timeZone: 'America/Danmarkshavn', hour: '2-digit', minute: '2-digit'}) : '' }}
                                    </td>
                                    <td> <button class="btn btn-primary" v-on:click="get_attendance(list.dsl_id)" data-toggle="modal" data-target="#mdl_attendance"> <i class="fa fa-eye"></i> </button> </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal animate__animated animate__fadeInDown animate__faster" id="mdl_attendance">
            <div class="modal-dialog modal-xl nrt">
                <div class="modal-content text-dark">
                    <div class="modal-header">
                        <button class="btn btn-danger" v-on:click="attendances = []" data-dismiss="modal"> &times; </button>
                        <h5 class="mt-2"> {{stafflanguage[language].list_deatail}} </h5>
                    </div>
                    <div class="modal-body" style="overflow-x: auto;">
                        <div class="alert alert-primary text-center" id="waiting" dir="rtl" style="display: none;"> تکایە چاوەڕوانبە... </div>
                        <table class="table table-striped table-bordered text-center text-dark">
                            <thead>
                                <th style="width: 7%;"> {{stafflanguage[language].ID}} </th>
                                <th> {{stafflanguage[language].emp_name}} </th>
                                <th style="width: 10%;"> {{stafflanguage[language].overtime}} </th>
                                <th style="width: 12%;"> {{stafflanguage[language].working_time}} </th>
                                <th style="width: 12%;"> {{stafflanguage[language].fine}} </th>
                                <th style="width: 20%;"> {{stafflanguage[language].fine_reason}} </th>
                                <th style="width: 10%;"> {{stafflanguage[language].present}} </th>
                                <th> {{stafflanguage[language].location}} </th>
                                <th> {{stafflanguage[language].main_staff}} </th>
                            </thead>
                            <tbody>  
                                <tr v-for="attendance in attendances" :key="attendance.emp_id">
                                    <td> {{ attendance.emp_id }} </td>
                                    <td> {{ attendance.full_name }} </td>
                                    <td> {{ attendance.overtime }} </td>
                                    <td> {{ attendance.worked_hours }} </td>
                                    <td> {{ attendance.fine }} </td>
                                    <td> {{ attendance.fine_reason }} </td>
                                    <td v-bind:class="{ 'text-success': attendance.absent == 0, 'text-danger': attendance.absent == 1, 'text-warning': attendance.absent == 2 }"> 
                                        {{ attendance.absent | absent_filter(language) }} 
                                    </td>
                                    <td> {{ attendance.location }} </td>
                                    <td> {{ attendance.main_staff }} </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import auth from '../../auth';
    import stafflanguage from '../../languages/staff_report.js'
    import { Bus } from '../../Bus.js'
    import $ from 'jquery'
    export default {
        name: "staffList",
        data() {
            return {
                month: new Date().getMonth(),
                year: new Date().getFullYear(),
                staffs: [],
                st_id: null,
                user: {
                    en_id: null,
                    role: null,
                    username: null
                },
                lists: [],
                attendances: [],
                stafflanguage,
                language:'English',
                showSpecialStaffs: "false"
            }
        },
        beforeCreate() {
            auth("staffListReport");
        },
        created() {
            this.$http.post("/getLoggedInfo").then(({data}) => {
                this.user.username = data.username;
                this.user.role = data.type;
                this.user.en_id = data.en_id || null;

                if(this.user.en_id == null){
                    this.$http.post("/staff/getData").then(({data}) => { this.staffs = data.filter(obj => obj.show_staff == '1') });
                } else {
                    this.$http.post("/staff/getByEngineer/" + this.user.en_id).then(({data}) => { this.staffs = data.filter(obj => obj.show_staff == '1') });
                }
                
            });
        },
        mounted(){
            Bus.$on('languageChanged',(data)=>{
                this.language=data;
                if(["Kurdish", "Arabic"].includes(data)){
                    $(".multi-language").addClass("rtl");
                    $("#wrapper").attr("dir", "rtl");
                } else {
                    $(".multi-language").removeClass("rtl");
                    $("#wrapper").attr("dir", "ltr");
                }
            });
        },
        methods: {
            search() {
                if(this.st_id && this.year && this.month){
                    this.$http.post("/daily_staff_list/dslReport/" + this.month + "/" + this.year + "/" + this.st_id).then(({data}) => {
                        this.lists = data;
                    });
                } else {
                    this.$alert("Fill all required fields", "Empty Field", "warning");
                }
            },
            get_attendance(dsl_id) {
                document.getElementById("waiting").style.display = "block";
                this.$http.post("/daily_staff_list/dslEachAttendance/" + dsl_id).then(({data}) => {
                    this.attendances = data.sort((a, b) => a.sort_code - b.sort_code);
                    document.getElementById("waiting").style.display = "none";
                });
            }
        },
        filters: {
            money_filter(value) {
                var num = value.toString().split("").reverse();
                var arr = [];
                for (let i = 0; i < num.length; i++) {
                    arr.unshift(num[i]);
                    if((i + 1) % 3 == 0){
                        arr.unshift(",");
                    }
                }
                var num_with_comma = arr.join("");
                if(num_with_comma.charAt(0) == ','){
                    return num_with_comma.slice(1);
                }
                return num_with_comma;
            },
            absent_filter(value, language) {
                return [
                    stafflanguage[language].present,
                    stafflanguage[language].absent,
                    stafflanguage[language].off
                ][value];
            },
            date_filter(value) {
                var d = new Date(value);
                return d.toISOString().split("T")[0];
            }
        }
    }
</script>

<style scoped>
    @font-face {
        font-family: nrt;
        src: url("../../assets/fonts/NRT-Reg.ttf");
    }
    .nrt {
        font-family: nrt;
    }
    td {
        padding: .5rem;
        direction: ltr;
    }
    @media print {
        .no-print{
            display: none;
        }
    }
    .rtl {
    text-align: right !important;
    }
</style>